<template>
  <div id="header-training-container" class="d-flex flex-row flex-nowrap justify-center align-center">
    <div id="header-training-title" v-if="$vuetify.breakpoint.width > 700">{{ title }}</div>
    <div v-if="result.score < 0" id="header-training-time" class="flex-grow-1">
      {{ time >= 0 ? formattedTime : '' }}
    </div>
    <div v-else id="header-training-score" class="flex-grow-1">{{ formattedResult }}</div>
    <div id="header-training-memo-time"> {{ formattedMemo }}</div>
    <v-btn id="header-training-button" color="primary" v-if="skip" @click="finish">{{ $t('common.end') }}</v-btn>
  </div>
</template>

<script>
export default {
  name: 'HeaderTraining',
  props: {
    title: {type: String, required: true},
    memo: {type: Number, required: false, default: -1}
  },
  data: () => ({
    end: 0, skip: true, onFinish: () => ({}),
    time: 0, countdown: undefined, result: {score: -1, time: -1}
  }),
  computed: {
    formattedMemo() {
      if (this.memo < 0) return '';
      let parsedResult = this.parseResult(0, this.memo).split(' ');
      return parsedResult[parsedResult.length - 1];
    },
    formattedResult() {
      return this.parseResult(this.result.score, this.result.time);
    },
    formattedTime() {
      return Math.floor(this.time / 60) + ':' + (this.time % 60 < 10 ? '0' : '') + (this.time % 60);
    }
  },
  methods: {
    finish() {
      if (this.result.score < 0) this.reset();
      else this.$router.replace(this.$store.state.location);
    },
    parseResult(score, time) {
      let m = Math.floor(time / 6000);
      let s = Math.floor(time / 100) - m * 60;
      let ms = time - m * 6000 - s * 100;
      let res;
      if (m === 0) res = s + '.' + ms.toString().padStart(2, '0');
      else res = m + ':' + s.toString().padStart(2, '0') + '.' + ms.toString().padStart(2, '0');
      return score + ' ' + this.$t('common.in') + ' ' + res;
    },
    setScore(score, time) {
      this.result.score = score;
      this.result.time = time;
    },
    start(end, skip, onFinish) {
      this.end = end;
      this.skip = skip;
      this.onFinish = onFinish;
      this.time = Math.ceil((this.end - new Date().getTime()) / 1000);
      this.countdown = setTimeout(() => this.reset(), this.end - new Date().getTime());
      this.updateTimer();
    },
    reset() {
      clearTimeout(this.countdown);
      this.onFinish();
      this.end = 0;
      this.skip = true;
      this.onFinish = () => ({});
    },
    updateTimer() {
      let now = new Date().getTime();
      this.time = Math.ceil((this.end - now) / 1000);
      if (now + 50 > this.end) return;
      setTimeout(() => this.updateTimer(), 50);
    }
  }
}
</script>

<style scoped>
#header-training-container {
  height: 70px;
  width: 100%;
}

#header-training-title {
  color: #336799;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 10px 20px;
  user-select: none;
  width: 260px;
}

#header-training-time {
  color: #336799;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 10px 20px;
  text-align: center;
  user-select: none;
}

#header-training-score {
  color: #336799;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 10px 20px;
  text-align: center;
  white-space: nowrap;
}

#header-training-memo-time {
  color: #336799;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 10px 0 10px 20px;
  text-align: right;
  width: 140px;
}

#header-training-button {
  margin: 10px 20px;
  width: 100px;
}
</style>
