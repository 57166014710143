<template>
  <div id="pagination-container" class="d-flex flex-row flex-nowrap justify-space-between align-start">
    <v-pagination id="pagination-pagination" :value="page" :length="pages" @input="onPageChange"/>
    <div style="user-select: none">&nbsp;</div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    page: {type: Number, required: true},
    pages: {type: Number, required: true},
    onPageChange: {type: Function, required: false, default: () => ({})}
  },
  mounted() {
    document.querySelectorAll('.v-pagination__navigation').forEach(el => el.style.display = 'none');
  }
}
</script>

<style scoped>
#pagination-container {
  height: 40px;
  width: 100%;
}

#pagination-pagination {
  margin-left: 16px;
  margin-top: -8px;
}
</style>
