<template>
  <div id="countdown-container">
    <div id="countdown-header-container" class="d-flex flex-row flex-nowrap justify-space-between align-center">
      <div id="countdown-header-title">{{ title }}</div>
      <v-btn id="countdown-header-button" color="primary" v-if="skip" @click="finish()">{{ $t('common.skip') }}</v-btn>
    </div>
    <div id="countdown-timer">{{ time }}</div>
  </div>
</template>

<script>
export default {
  name: 'Countdown',
  props: {
    title: {type: String, required: true},
  },
  data: () => ({
    end: undefined, skip: undefined, onFinish: undefined,
    time: 0, countdown: undefined
  }),
  methods: {
    finish() {
      clearTimeout(this.countdown);
      this.onFinish();
      document.getElementById('countdown-container').style.display = 'none';
    },
    start(end, skip, onFinish) {
      document.getElementById('countdown-container').style.display = 'block';
      this.end = end;
      this.skip = skip;
      this.onFinish = onFinish;
      this.time = Math.ceil((this.end - new Date().getTime()) / 1000);
      this.countdown = setTimeout(() => {
        this.onFinish();
        document.getElementById('countdown-container').style.display = 'none';
      }, this.end - new Date().getTime());
      this.updateTimer();
    },
    updateTimer() {
      let now = new Date().getTime();
      this.time = Math.ceil((this.end - now) / 1000);
      if (now + 50 > this.end) return;
      setTimeout(() => this.updateTimer(), 50);
    }
  }
}
</script>

<style scoped>
#countdown-container {
  background-color: #e0d8d5;
  bottom: 0;
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 30px;
}

#countdown-header-container {
  height: 70px;
  width: 100%;
}

#countdown-header-title {
  color: #336799;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 10px 20px;
  user-select: none;
}

#countdown-header-button {
  margin: 10px 20px;
  width: 100px;
}

#countdown-timer {
  align-items: center;
  color: #336799;
  display: flex;
  font-size: 80px;
  font-weight: 500;
  height: calc(100% - 70px);
  justify-content: center;
  padding-bottom: 70px;
  user-select: none;
}
</style>
