<template>
  <div id="mobile-arrows-container" class="d-flex flex-row flex-nowrap align-center justify-center">
    <v-icon size="50" class="arrow" color="white" @click="onLeft">mdi-arrow-left-thin</v-icon>
    <v-icon size="50" class="arrow" color="white" @click="onRight">mdi-arrow-right-thin</v-icon>
  </div>
</template>

<script>
export default {
  name: 'MobileArrows',
  props: {
    onLeft: {type: Function, required: false, default: () => ({})},
    onRight: {type: Function, required: false, default: () => ({})}
  }
};
</script>

<style scoped>
#mobile-arrows-container {
  background-color: #e0d8d5;
  bottom: 0;
  height: 60px;
  padding: 5px;
  position: fixed;
  width: 100%;
}

#mobile-arrows-container .arrow {
  background-color: #336799;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 10px;
}

</style>
